import React, { createContext, useState } from 'react';
import { mediaService } from '../service/MediaService';
import { projectService } from '../service/ProjectService';
import { userService } from '../service/UserService';
import { appState } from '../state/AppState'
import {s3Service} from '../service/S3Service'

const initialAppState = { value: appState }
const StorageKeyToken = "StorageKeyToken"

class Store {

    _projectMediaCache = {}

    _appStateSet = undefined;

    set appStateSet(val) {
        this._appStateSet = val
    }

    get appStateSet() { return this._appStateSet }

    constructor(){
        
    }

    get currentUser(){
        const [appState, setAppState] = this.appStateSet
        return appState.value.currentUser
    }

    get currentUserId(){
        return this.currentUser.id
    }

    login = (email, password) => {
        return userService.login(email, password)
        .then(user => {
            console.log("token", user.token)
            this.setValue("currentUser", user)
            setTimeout(()=>{
                this.setLoginToken(user.token)
                this.fetchInitialData()
            },10)
            return user
        })
    }

    setValue = (key, value) => {
        const [appState, setAppState] = this.appStateSet
        appState.value[key] = value
        setAppState({ value: appState.value })
    }

    setLoginToken = (token) => {
        window.localStorage.setItem(StorageKeyToken, token)
    }

    deleteLoginToken = (token) => {
        window.localStorage.removeItem(StorageKeyToken)
    }

    loginWithToken = (token) => {
        return userService.loginWithToken(token)
        .then(user=>{
            this.setValue("currentUser", user)
            this.fetchInitialData()
            return user
        })
    }

    logout = () => {
        this.deleteLoginToken()
        this.setValue("currentUser", undefined)
    }

    get token(){
        return window.localStorage.getItem(StorageKeyToken)
    }

    fetchInitialData = () => {
        this.fetchProjectList()
        s3Service.presignedUrl("1/title.png", "image/png").then(result=>{
            console.log("presignedUrl", result)
        })
    }

    fetchProjectList = () => {
        return projectService.fetch().then((projectList)=>{
            this.setValue("projectList", projectList)
        })
        .catch(e=>{            
            console.log("e", e)
        })
    }

    fetchMediaList = (projectId) => {
        const cache = this._projectMediaCache[projectId]
        if(cache){
            console.log("useCache")
            return Promise.resolve(cache)
        }

        return mediaService.fetchWithProjectId(projectId).then(results=>{
            const [mediaList, influencerList] = results
            mediaList.forEach(media => {
                media.influencer = influencerList.find(row=>row.id === media.influencerId)
            });
            this._projectMediaCache[projectId] = mediaList
            return mediaList
        })
    }

    uploadTitleImage = (projectId, base64Data) => {
        const clientId = this.currentUserId
        return this.uploadImage([projectId, "title"], base64Data).then((result) => {
            return projectService.patch(1, "title_image", clientId + "/" + result.key)
        }).then(result => {
            console.log("patched", result)
            return result
        })
    }

    uploadEcTitleImage = (projectId, base64Data) => {
        const clientId = this.currentUserId
        return this.uploadImage([projectId, "ec_title"], base64Data).then((result) => {
            return projectService.patch(1, "ec_title_image", clientId + "/" + result.key)
        }).then(result => {
            console.log("patched", result)
            return result
        })
    }

    uploadImage = (pathList, base64Data) => {
        return s3Service.uploadWith(pathList, base64Data)
    }

    updateProject = (id, data) => {
        return projectService.update(id, data).then(project=>{
            
            const [appState, setAppState] = this.appStateSet
            this.setValue("projectList", appState.value.projectList.slice().map(row=>{
                return row.id === project.id ? project : row
            }))
            return project
        })
    }

    updateProjectLink = (id, linkList) => {

        const [appState, setAppState] = this.appStateSet
        const target = appState.value.projectList.find(row => row.id == id)
        target.linkList = linkList

        return projectService.updateProjectLink(id, linkList)
    }
}

const store = new Store()

export const AppContext = createContext();

const { Provider } = AppContext;

export const StoreProvider = ({ children }) => {
    const appStateSet = useState(initialAppState)

    store.appStateSet = appStateSet
    const appState = appStateSet[0]

    return <Provider value={{ appState }}>{children}</Provider>
}

export const useAppState = () => {
    return React.useContext(AppContext).appState.value;
}

export default store
