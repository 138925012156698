import Media from "../entities/Media";
import Influencer from "../entities/Influencer";
import ServiceUtil from "./ServiceUtil";
import { API } from "../Config";

class MediaService {

    fetchWithProjectId = (projectId) => {
        return ServiceUtil.get(API.resources.media + "/" + projectId).then((response)=>{
            return response.json();
        }).then(json=>{
            const mediaList = json.media_list.map(row=>new Media(row))
            const incluencerList = json.influencer_list.map(row=> new Influencer(row))
            return [mediaList, incluencerList]
        })
    }

}

const mediaService = new MediaService()

export { mediaService }

