import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import { colors } from '../../../style';
import ProjectForm from './ProjectForm';
import ProjectMediaList from './ProjectMediaList';

const Wrapper = styled.div`
    position:absolute;
    top:0px;left:0px;right:0px;bottom:0px;
`

const Header = styled.div`
    position:absolute;
    top:0px;left:0px;right:0px;
    height:64px;
`

const HeaderContents = styled.div`
    display:flex;
    width:100%;
    height:100%;
    justify-content:space-between;
    align-items:center;
    padding:0px 20px;
`

const BackButton = styled.button`
    font-size:16px;
    border:1px solid ${colors.primary};
    color:${colors.primary};
    padding: 10px 20px;
    border-radius:5px;
`

const LPLink = styled.p`
    a{
        display:inline-block;
        padding:10px 20px;
        color:${colors.primary};
        font-size:16px;

        :hover{
            text-decoration:underline;
        }
    }
`

const HeaderTitle = styled.h2`
    font-size:30px;
    font-weight:bold;
`

const Contents = styled.div`
    background-color:${colors.white};
    position:absolute;
    top:0px;left:0px;right:0px;bottom:0px;
`

const ProjectContents = styled.div`
    position:absolute;
    top:64px;
    left:0px;right:0px;bottom:0px;
    overflow-y:scroll;
`

class ProjectScreenModel{

    constructor(
        appState, 
        history,
        projectId,
        [mediaList, setMediaList]
    ){
        this.appState = appState
        this.history = history
        this.projectId = parseInt(projectId)
        this.mediaList = mediaList
        this.setMediaList = setMediaList
    }

    get project(){
        const { projectList } = this.appState
        if(!projectList){
            return undefined
        }
        return projectList.find(row=>row.id === this.projectId)
    }

    clickBack = () => {
        this.history.goBack()
    }

    onAppear = () => {
        const {projectId} = this
        if(!projectId){
            return
        }
        store.fetchMediaList(projectId).then((mediaList)=>{
            this.setMediaList(mediaList)
        })
    }
}

const ProjectScreen = ({match}) => {
    const {params} = match
    const {id} = params
    const viewModel = new ProjectScreenModel(
        useAppState(), 
        useHistory(),
        id,
        useState(undefined) // mediaList
    )
    const {project, projectId, mediaList} = viewModel

    useEffect(viewModel.onAppear, [projectId])

    if(!project){
        return <Wrapper>
            <Contents>
                <p>Loading</p>
            </Contents>
        </Wrapper>
    }

    return <Wrapper>
        <Contents>
            <Header>
                <HeaderContents>
                    <BackButton onClick={viewModel.clickBack}>戻る</BackButton>
                    <HeaderTitle>{project.name}</HeaderTitle>
                    <LPLink><a href={project.lpUrl} target="_blank">公開ページ</a></LPLink>
                </HeaderContents>
            </Header>
            <ProjectContents>
                <ProjectForm project={project} />
                {mediaList && <ProjectMediaList mediaList={mediaList} />}
            </ProjectContents>
        </Contents>
    </Wrapper>
}

export default ProjectScreen