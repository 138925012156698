class Client {

    constructor(data, created = false) {
        if(!data){
            throw new Error("client data failed")
        }
        this._data = data
        this.created = created

        try{
            this.tagList = JSON.parse(this._data.tag_list)
        }catch(e){}
        
        if(!Array.isArray(this.tagList)){
            this.tagList = []
        }
    }

    get id() { return this._data.id }
    get name() { return this._data.name }
    get email() { return this._data.email }
    get token(){
        return this._data.token
    }

}

export default Client