import logo from './logo.svg';
import './App.css';
import LoginScreen from './screens/public/LoginScreen'; 
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoginedScreen from './screens/logined/LoginedScreen';
import store, { StoreProvider, useAppState } from './store/store';
import { useEffect, useState } from 'react';

const Contents = () => {

  const [loading, setLoading] = useState(true)
  const appState = useAppState()
  const {isLogined} = appState

  useEffect(()=>{
    if(!store.token){
      setLoading(false)
      return
    }

    store.loginWithToken(store.token).then(user=>{
      console.log("logined", user)
    }).catch((error)=>{
      console.log("token login failed", error)
    }).finally(()=>{
      setLoading(false)
    })
  }, [])

  return <BrowserRouter>
    <Switch>
      {isLogined && <Route path={"/"} component={LoginedScreen} />}
      {!isLogined && <Route path={"/"} component={LoginScreen} />}        
    </Switch>
  </BrowserRouter>
}

const App = () => {
  return <StoreProvider>
    <Contents />
  </StoreProvider>
}

export default App;
