import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import { colors } from '../../../style';
import * as ui from '../../../ui';
import ECLinkForm from './ECLinkForm';
import ProjectImageForm from './ProjectImageForm';

const Wrapper = styled.div`
    padding:20px;
`

const Title = styled.h2`
    font-size:30px;
    font-weight:bold;
`

const InputWrapper = styled(ui.InputWrapper)`
    margin-top:20px;
    padding:0px 20px;
`

const ECView = styled.div`
    padding:20px;
`

const ECLinkTitle = styled.h3`
    margin-top:20px;
`

const ECLinkListView = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
`

const ProjectForm = ({project}) => {

    const {tagList, linkList} = project

    const [lpTitle, setLpTitle] = useState(project.lpTitle)
    const [description, setDescription] = useState(project.description)
    const [youtubeUrl, setYoutubeUrl] = useState(project.youtubeUrl)

    const tryUpdate = () => {
        const data = {
            lp_title: lpTitle,
            youtube_url: youtubeUrl,
            description: description
        }

        store.updateProject(project.id, data).then(results=>{
            console.log("---- updated -----")
            console.log(results)
        }).catch(e=>{
            console.error(e)
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && e.keyCode === 13) {
            // tryUpdate()
            e.target.blur()
        }
    } 

    return <Wrapper>
        <Title>プロジェクト情報</Title>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>LPタイトル</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="text"
                value={lpTitle}
                onChange={(e)=>setLpTitle(e.target.value)}
                onBlur={tryUpdate}
                onKeyDown={handleKeyDown}
            />
        </InputWrapper>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>YoutubeUrl</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="text"
                value={youtubeUrl}
                onChange={(e)=>setYoutubeUrl(e.target.value)}
                onBlur={tryUpdate}
                onKeyDown={handleKeyDown}
            />
        </InputWrapper>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>説明</ui.InputLabel>
            </ui.InputInfo>
            <ui.TextArea
                value={description}
                rows={10}
                onBlur={tryUpdate}
                onChange={(e) => { setDescription(e.target.value) }}
            />
        </InputWrapper>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>タグ</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="text"
                readOnly={true}
                value={tagList.join(",")}
            />
        </InputWrapper>
        <ProjectImageForm project={project} />
        <ECLinkForm project={project} />
        {/* <ECView>
            <ECLinkTitle>ECリンク</ECLinkTitle>
            <ECLinkListView>
                {linkList.map((data, index)=>{
                    return <ECLinkForm data={data} key={index} />
                })}
            </ECLinkListView>
        </ECView> */}
    </Wrapper>
}

const ECLinkFormView = styled.div`
    margin-top:20px;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
`

const ECLinkImage = styled.img`
    width:100px;
    height:100px;
    object-fit:cover;
    margin-right:10px;
    margin-top:20px;
`

const ECLinkData = styled.div`
    flex:1;
`

// const ECLinkForm = ({data})=>{
//     const {linkUrl, imageUrl} = data

//     return <ECLinkFormView>
//         <a href={linkUrl} target="_blank"><ECLinkImage 
//             src={imageUrl} 
//         /></a>

//         <ECLinkData>
//             <ui.InputWrapper>
//                 <ui.InputInfo>
//                     <ui.InputLabel>画像</ui.InputLabel>
//                 </ui.InputInfo>
//                 <ui.Input
//                     type="text"
//                     readOnly={true}
//                     value={imageUrl}
//                 />
//             </ui.InputWrapper>

//             <ui.InputWrapper>
//                 <ui.InputInfo>
//                     <ui.InputLabel>リンク</ui.InputLabel>
//                 </ui.InputInfo>
//                 <ui.Input
//                     type="text"
//                     readOnly={true}
//                     value={linkUrl}
//                 />
//             </ui.InputWrapper>
//         </ECLinkData>
//     </ECLinkFormView>
// }

export default ProjectForm