import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import { colors } from '../../../style';
import ProjectRowView from './ProjectRowView';

const Wrapper = styled.div`
    position:absolute;
    top:0px;left:0px;right:0px;bottom:0px;
`

const Header = styled.div`
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    height:64px;
    background-color:${colors.white};
`

const ContentsWrapper = styled.div`
    position:absolute;
    top:64px;left:0px;right:0px;bottom:0px;
    overflow-y:scroll;
`

const Contents = styled.div`
    padding:30px;
`

const HeaderContents = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    height:100%;
    align-items:center;
    padding:0px 20px;
`

const LogoutButton = styled.button`
    padding:10px 20px;
    background-color:${colors.white};
    color:${colors.primary};
    font-size:16px;
    border:1px solid ${colors.primary};
    border-radius:4px;
    :hover{
        background-color:${colors.primary};
        color:${colors.white};
    }
`

const ProjectListView = styled.div`
    margin-bottom:50px;
`

const Title = styled.h2`
    font-size:24px;
    font-weight:bold;
`

const SectionTitle = styled.h3`
    font-size:30px;
    font-weight:bold;
`

class HomeScreenModel{
    constructor(appState){
        this.appState = appState
    }

    get projectList(){
        const {projectList} = this.appState
        return projectList ?? []
    }

    get currentUser(){
        const {appState} = this
        return appState.currentUser
    }

    get username(){
        return this.currentUser.name
    }
}


const HomeScreen = () => {
    const viewModel = new HomeScreenModel(useAppState())
    const {projectList, username} = viewModel

    return <Wrapper>
        <Header>
            <HeaderContents>
                <Title>{username}</Title>
                <LogoutButton onClick={store.logout}>
                    ログアウト
                </LogoutButton>
            </HeaderContents>
        </Header>
        <ContentsWrapper>
            <Contents>
                <SectionTitle>プロジェクト</SectionTitle>
                <ProjectListView>
                    {projectList.map(project=>{
                        return <ProjectRowView project={project} key={project.id} />
                    })}
                </ProjectListView>

                <SectionTitle>おすすめのインフルエンサー</SectionTitle>
                <p>TODO</p>
            </Contents>
        </ContentsWrapper>
    </Wrapper>
}

export default HomeScreen