import store from "../store/store"

class ServiceUtil{
    static post = (url, values) =>{
        return fetch(url, {
            method: "POST",
            headers: this._headers,
            body: JSON.stringify(values)
        })
    }

    static put = (url, values) =>{
        return fetch(url, {
            method: "PUT",
            headers: this._headers,
            body: JSON.stringify(values)
        })
    }

    static patch = (url, values) =>{
        return fetch(url, {
            method: "PATCH",
            headers: this._headers,
            body: JSON.stringify(values)
        })
    }

    static get = (url) => {
        return fetch(url, {
            method: "GET",
            headers: this._headers
        })
    }

    static get _headers(){
        const headers = {
            "Content-Type": "application/json"
        }

        const token = store.token
        if(token){
            headers["Authorization"] = `Bearer ${token}`
        }

        return headers
    }

}

export default ServiceUtil