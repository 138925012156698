import { colors } from "../style"
import styled from 'styled-components'

export const InputWrapper = styled.div`
  margin-bottom:0px;
`

export const InputInfo = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items: center;
    height:20px;
`

export const InputLabel = styled.h6`
    font-size:14px;
`

export const Input = styled.input`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    height:44px;
    flex:1;
    padding:0px 10px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`


export const PageTitle = styled.h1`
    font-size:30px;
    font-weight:bold;
    margin-bottom:20px;
`

export const PageSubTitle = styled.h2`
    font-size:20px;
    margin-bottom:20px;
`

export const Loader = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #9999ff;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  box-sizing: border-box;
  ::before,
  ::after {
    box-sizing: border-box;
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }

  border-top-color: transparent;
  ::after {
    display: block;
    left: -2px;
    top: -2px;
    border: inherit;
    transform: rotate(65deg);
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Select = styled.select`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    height:44px;
    padding:0px 10px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`

export const TextArea = styled.textarea`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    flex:1;
    padding:10px 10px;
    font-size:20px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`