import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import * as ui from '../../../ui'
import { colors, size } from '../../../style';
import util from '../../../util';

const Wrapper = styled.div`
    padding:30px;
    padding-top:0px;
    flex:1;
    margin-top:30px;
`

const Inner = styled.div`
    display: grid;
    grid-column-gap: 60px;
    width:100%;
    grid-template-columns: 1fr 1fr;
`

const ImageWrapper = styled.div`
    height:300px;
    background-color:${colors.disable};
    border:1px solid ${colors.gray};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    :hover{
        opacity:0.9;
    }
`

const RowView = styled.div`

`

const ImageView = styled.img`
    object-fit: contain;
    width:100%;
    height:100%;
`

const InputFile = styled.input`
    display:none;
`

class ProjectImageFormViewModel {

    constructor(project, appState, [count, setCount]) {
        this.project = project
        this.appState = appState
        this.count = count
        this.setCount = setCount
    }

    handleClickTitleImage = (event) => {
        const fileDom = document.getElementById("titleimage")
        fileDom.click()
    }

    handleClickEcTitleImage = (event) => {
        const fileDom = document.getElementById("ectitleimage")
        fileDom.click()
    }

    handleChangeTitleImage = (event) => {
        const project = this.project
        const projectId = project.id
        const file = event.target.files[0]
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            util.resizeImage(reader.result, 600, 600).then(result => {
                return store.uploadTitleImage(projectId, result)
            }).then(results => {
                console.log("results", results)
                this.project.titleImage = results.title_image + "?" + util.now
                this.setCount(this.count + 1)
            })
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleChangeEcTitleImage = (event) => {
        const project = this.project
        const projectId = project.id
        const file = event.target.files[0]
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            util.resizeImage(reader.result, 600, 600).then(result => {
                return store.uploadEcTitleImage(projectId, result)
            }).then(results => {
                console.log("results", results)
                this.project.ecTitleImage = results.ec_title_image + "?" + util.now
                this.setCount(this.count + 1)
            })
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }
}

const ProjectImageForm = ({ project }) => {

    const viewModel = new ProjectImageFormViewModel(project, useAppState(), useState(0))
    const { hasTitleImage, titleImage, hasEcTitleImage, ecTitleImage } = project

    return <Wrapper>
        <ui.PageTitle>画像</ui.PageTitle>
        <Inner>
            <RowView>
                <ui.PageSubTitle>タイトル画像</ui.PageSubTitle>
                <ImageWrapper onClick={viewModel.handleClickTitleImage}>
                    {hasTitleImage && <ImageView src={titleImage} />}
                    <InputFile type="file" id="titleimage" onChange={viewModel.handleChangeTitleImage} />
                </ImageWrapper>
            </RowView>
            <RowView>
                <ui.PageSubTitle>ECタイトル画像</ui.PageSubTitle>
                <ImageWrapper onClick={viewModel.handleClickEcTitleImage}>
                    {hasEcTitleImage && <ImageView src={ecTitleImage} />}
                    <InputFile type="file" id="ectitleimage" onChange={viewModel.handleChangeEcTitleImage} />
                </ImageWrapper>
            </RowView>
        </Inner>
    </Wrapper>
}

export default ProjectImageForm