import Influencer from "../entities/Influencer";
import { API } from '../Config'
import ServiceUtil from "./ServiceUtil";
import Client from "../entities/Client";

class UserService {

    login = (email, password) => {
        return ServiceUtil.post(API.resources.login, {
            email: email,
            password: password,
        })
        .then(res => res.json())
        .then(json => new Client(json))
    }

    loginWithToken = (token) => {
        const url = API.resources.login + "/" + token
        return ServiceUtil.get(url)
        .then(res => res.json())
        .then(json => new Client(json))
    }
}

const userService = new UserService()

export { userService }