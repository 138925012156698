import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router';
import styled from 'styled-components'
import HomeScreen from './home/HomeScreen';
import ProjectScreen from './project/ProjectScreen';

const Wrapper = styled.div`
    position:absolute;
    top:0px;left:0px;right:0px;bottom:0px;
`

const LoginedScreen = () => {
    return <Wrapper>
        <Route path="/" component={HomeScreen} />
        <Route path="/project/:id" component={ProjectScreen} />
    </Wrapper>
}

export default LoginedScreen

