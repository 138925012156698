import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store from '../../store/store';
import { colors } from '../../style';
import * as ui from '../../ui';
import util from '../../util'


const Wrapper = styled.div`
    background-color:${colors.primary};
    width:100vw;
    height:100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`

const LoginWrapper = styled.div`
    width:90%;
    max-width:400px;
    position:relative;
    top:-30px;
`

const LoginContents = styled.div`
    padding:40px;
    border-radius:10px;
    background-color:${colors.white};
`

const Title = styled.h1`
    font-size:40px;
    text-align:center;
    font-weight:bold;
    color:${colors.white};
    margin-bottom:30px;
`

const Spacer = styled.div`
    height:20px;
`

const LoginButton = styled.button`
    display:block;
    background-color:${colors.primary};
    width:100%;
    padding:10px 0px;
    color:${colors.white};
    font-size:20px;
    text-align:center;
    border-radius:4px;
    cursor:pointer;
    margin-top:30px;


    ${({disabled})=> {
        return disabled ? `
        background-color:${colors.buttonDisable};
    ` : `
    :hover{
        opacity:0.9;
    }
    `}}

    ${({error})=> {
        if(error){
            return `
                background-color:${colors.danger};
            `
        }
    }}
`

class LoginScreenModel{
    constructor(
        [email, setEmail] 
        , [password, setPassword] 
        , [error, setError]
        , [loading, setLoading]
    ){
        this.email = email
        this.setEmail = setEmail
        this.password = password
        this.setPassword = setPassword
        this.error = error
        this.setError = setError
        this.loading = loading
        this.setLoading = setLoading
    }

    changePassword = (e) => {
        this.setPassword(e.target.value)
        this.setError(false)
    }

    changeEmail = (e) => {
        this.setEmail(e.target.value)
        this.setError(false)
    }

    get validEmail(){
        return util.testEmail(this.email)
    }

    get validPassword() {
        return this.password.length > 0
    }

    get formDisabled(){
        return !this.validEmail || !this.validPassword || this.loading || this.error
    }

    keyDown = (e) =>{
        if (e.key === "Enter" && e.keyCode === 13) { 
            this.tryLogin()
        }
    }

    tryLogin = () => {
        if(this.formDisabled){
            return
        }
        this.setLoading(true)
        store.login(this.email, this.password).then(results=>{
            console.log("results", results)
        })
        .catch(error=>{
            console.log("login error", error)
            this.setError(true)
        })
        .finally(()=>{
            this.setLoading(false)
        })
    }

    clickButton = (e) => {
        this.tryLogin()
    }

    get buttonTitle(){
        if(this.error){
            return "アカウントが見つかりませんでした"
        }
        return this.loading ? "ログイン中..." : "ログイン"
    }
}

const LoginScreen = () => {

    const viewModel = new LoginScreenModel(
        useState(""),  // email
        useState(""), // password
        useState(false), // error
        useState(false) // loading
    )
    const {
        email, password, loading, 
        validEmail, validPassword,
        formDisabled, buttonTitle,
        error,
        changeEmail, changePassword, clickButton, keyDown
    } = viewModel

    return <Wrapper>
        <LoginWrapper>
            <Title>VReach</Title>
            <LoginContents>
                <ui.InputWrapper>
                    <ui.InputInfo>
                        <ui.InputLabel>メールアドレス</ui.InputLabel>
                    </ui.InputInfo>
                    <ui.Input
                        type="text"
                        valid={validEmail}
                        value={email}
                        disable={loading}
                        onKeyDown={keyDown}
                        onChange={changeEmail}
                    />
                </ui.InputWrapper>
                <Spacer />
                <ui.InputWrapper>
                    <ui.InputInfo>
                        <ui.InputLabel>パスワード</ui.InputLabel>
                    </ui.InputInfo>
                    <ui.Input
                        type="password"
                        valid={validPassword}
                        value={password}
                        disable={loading}
                        onKeyDown={keyDown}
                        onChange={changePassword}
                    />
                </ui.InputWrapper>

                <LoginButton 
                    onClick={clickButton}
                    disabled={formDisabled}
                    error={error}
                >{buttonTitle}</LoginButton>
            </LoginContents>
        </LoginWrapper>
    </Wrapper>
}

export default LoginScreen

