import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import { colors } from '../../../style';

const Wrapper = styled.div`
    padding:20px 0px;
    border-bottom:1px solid ${colors.border};
`

const UpperView = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
`

const ProjectTitle = styled.h2`
    font-size:20px;
`

const LinkText = styled.p`
    a{
        color:${colors.primary};
        :hover{
            text-decoration:underline;
        }
    }
`

const TagListView = styled.div`
    margin-top:10px;
    ul{
        display:flex;
        width:100%;
        li{
            font-size:14px;
        }
    }
`

const ExLink = styled(Link)`

`

const ProjectRowView = ({project}) => {

    const {tagList} = project
    
    return <Wrapper>
        <UpperView>
            <ProjectTitle>
                <ExLink to={`/project/${project.id}`}>{project.name}</ExLink>
            </ProjectTitle>
            {project.hasLp && <LinkText>
                <a href={project.lpUrl} target="_blank">
                    LPページ
                </a>
            </LinkText>}
        </UpperView>
        {project.hasTag && <TagListView>
            <ul>
                {tagList.map((tag, index)=>{
                    return <li key={index}>#{tag}</li>
                })}
            </ul>
        </TagListView>}
    </Wrapper>
}

export default ProjectRowView