import { API } from '../Config'
import ServiceUtil from "./ServiceUtil";
import Project from "../entities/Project";

class ProjectService {

    fetch = () => {
        return ServiceUtil.get(API.resources.project).then((response) => {
            return response.json();
        }).then(json => {
            if(!json){
                throw new Error("project not found")
            }
            return json.map(row=>new Project(row))
        })
    }

    patch = (id, key, value) => {
        return ServiceUtil.patch(`${API.resources.project}/${id}`, { key: key, value: value }).then(response => {
            return response.json()
        })
    }

    update = (id, data) => {
        return ServiceUtil.put(`${API.resources.project}/${id}`, data).then(response => {
            return response.json()
        }).then(json=>{
            return new Project(json)
        })
    }

    updateProjectLink = (id, linkList) => {
        return ServiceUtil.put(API.resources.projectLink + "/" + id, {
            link_list: JSON.stringify(linkList),
        })
    }
}

export const projectService = new ProjectService()
