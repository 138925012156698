import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import TagMediaView from '../../../components/TagMediaView';


const Wrapper = styled.div`
    padding:20px 20px;
`

const Title = styled.h2`
    font-size:30px;
    font-weight:bold;
`

const MediaListWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding:20px;
`

const ProjectMediaList = ({mediaList}) => {
    return <Wrapper>
        <Title>
            Instagram投稿
        </Title>
        <MediaListWrapper>
            {mediaList.map(media=><TagMediaView media={media} key={media.id} />)}
        </MediaListWrapper>
    </Wrapper>
}

export default ProjectMediaList