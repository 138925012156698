class AppState {

    projectList = undefined
    currentUser = undefined

    constructor() {
        console.log("AppState.constructor")
    }

    get isLogined(){
        return !!this.currentUser
    }
}

const appState = new AppState()

export default AppState
export { appState }